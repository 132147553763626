function Footer() {
  return (
    <div>
      <p>Created by Lewis Hardcastle</p>
      <p>personal@lewishardcastle.io</p>
    </div>
  );
}

export default Footer;
